/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddMenuRoleRequest {
  /** @format int32 */
  businessUnitId: number;
  canWrite: boolean;
  /** @format int32 */
  menuItemId: number;
  /** @format uuid */
  roleId: string;
}

export interface AggregateDescriptor {
  aggregates?: AggregateFunction[] | null;
  member?: string | null;
}

export interface AggregateFunction {
  aggregateMethodName?: string | null;
  caption?: string | null;
  functionName?: string | null;
  memberType: Type;
  resultFormatString?: string | null;
  sourceField?: string | null;
}

export interface Assembly {
  /** @deprecated */
  codeBase?: string | null;
  customAttributes: CustomAttributeData[];
  definedTypes: TypeInfo[];
  entryPoint: MethodInfo;
  /** @deprecated */
  escapedCodeBase: string;
  exportedTypes: Type[];
  fullName?: string | null;
  /** @deprecated */
  globalAssemblyCache: boolean;
  /** @format int64 */
  hostContext: number;
  imageRuntimeVersion: string;
  isCollectible: boolean;
  isDynamic: boolean;
  isFullyTrusted: boolean;
  location: string;
  manifestModule: Module;
  modules: Module[];
  reflectionOnly: boolean;
  securityRuleSet: SecurityRuleSet;
}

export interface AuthenticationResponse {
  availableLegTypes: number[];
  availableStageTypes: number[];
  businessUnits: ViewBaseResponse[];
  refreshToken: string;
  roles: ViewRoleResponse[];
  token: string;
  userEmail?: string | null;
  userFirstName: string;
  userId: string;
  userLastName: string;
}

export interface BusinessUnitListParams {
  request?: DataSourceRequest;
}

export interface BusinessUnitOrganisationListParams {
  request?: DataSourceRequest;
}

/** @format int32 */
export type CallingConventions = 1 | 2 | 3 | 32 | 64;

export interface CheckTokenRequest {
  email: string;
  purpose: TokenPurpose;
  token: string;
}

export interface ClientHandlerDescriptor {
  handlerName?: string | null;
  templateDelegate: ObjectObjectFunc;
}

export interface ConstructorInfo {
  attributes: MethodAttributes;
  callingConvention: CallingConventions;
  containsGenericParameters: boolean;
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  isAbstract: boolean;
  isAssembly: boolean;
  isCollectible: boolean;
  isConstructedGenericMethod: boolean;
  isConstructor: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isFinal: boolean;
  isGenericMethod: boolean;
  isGenericMethodDefinition: boolean;
  isHideBySig: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isVirtual: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  methodHandle: RuntimeMethodHandle;
  methodImplementationFlags: MethodImplAttributes;
  module: Module;
  name: string;
  reflectedType: Type;
}

export interface CreateBusinessUnitBankRequest {
  accountNumber?: string | null;
  /** @format int32 */
  currencyId: number;
  iban?: string | null;
  sortCode?: string | null;
  vatRegNumber?: string | null;
}

export interface CreateBusinessUnitRequest {
  address?: string | null;
  availableLegTypes: number[];
  availableStageTypes: number[];
  /** @format int32 */
  currencyId?: number | null;
  description: string;
  email?: string | null;
  fax?: string | null;
  internalCode: string;
  logo?: string | null;
  name: string;
  /** @format uuid */
  organisationId: string;
  phone?: string | null;
  transferCustomerAccountCode?: string | null;
  zipCode?: string | null;
}

export interface CreateBusinessUnitSettingsRequest {
  key: string;
  value?: string | null;
}

export interface CreateOrganisationRequest {
  description: string;
  name: string;
}

export interface CreateUserRequest {
  /** @format int32 */
  driverId?: number | null;
  email: string;
  firstName: string;
  lastName: string;
  /** @format uuid */
  organisationId?: string | null;
  roleId: string;
}

export interface CustomAttributeData {
  attributeType: Type;
  constructor: ConstructorInfo;
  constructorArguments: CustomAttributeTypedArgument[];
  namedArguments: CustomAttributeNamedArgument[];
}

export interface CustomAttributeNamedArgument {
  isField: boolean;
  memberInfo: MemberInfo;
  memberName: string;
  typedValue: CustomAttributeTypedArgument;
}

export interface CustomAttributeTypedArgument {
  argumentType: Type;
  value?: any;
}

export interface DataSourceRequest {
  aggregates?: AggregateDescriptor[] | null;
  caseSensitiveFilter: boolean;
  filters?: IFilterDescriptor[] | null;
  groupPaging: boolean;
  groups?: GroupDescriptor[] | null;
  includeSubGroupCount: boolean;
  isExcelExportRequest: boolean;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  skip: number;
  sorts?: SortDescriptor[] | null;
  /** @format int32 */
  take: number;
}

/** @format int32 */
export type EventAttributes = 0 | 512 | 1024;

export interface EventInfo {
  addMethod: MethodInfo;
  attributes: EventAttributes;
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  eventHandlerType: Type;
  isCollectible: boolean;
  isMulticast: boolean;
  isSpecialName: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  module: Module;
  name: string;
  raiseMethod: MethodInfo;
  reflectedType: Type;
  removeMethod: MethodInfo;
}

/** @format int32 */
export type FieldAttributes =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 16
  | 32
  | 64
  | 128
  | 256
  | 512
  | 1024
  | 4096
  | 8192
  | 32768
  | 38144;

export interface FieldInfo {
  attributes: FieldAttributes;
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  fieldHandle: RuntimeFieldHandle;
  fieldType: Type;
  isAssembly: boolean;
  isCollectible: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isInitOnly: boolean;
  isLiteral: boolean;
  /** @deprecated */
  isNotSerialized: boolean;
  isPinvokeImpl: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  module: Module;
  name: string;
  reflectedType: Type;
}

export interface ForgotPasswordRequest {
  /** @format email */
  email: string;
}

/** @format int32 */
export type GenericParameterAttributes = 0 | 1 | 2 | 3 | 4 | 8 | 16 | 28;

export interface GenericQueryFilter {
  roleIds?: string[] | null;
  userIds?: string[] | null;
}

export interface GetVersionResponse {
  version?: string | null;
}

export interface GlobalErrorModel {
  /** @format int32 */
  errorCode?: number | null;
  errors: ValidationError[];
  innerException?: string | null;
  message: string;
  stackTrace?: string | null;
}

export interface GroupDescriptor {
  aggregateFunctions?: AggregateFunction[] | null;
  displayContent?: any;
  member?: string | null;
  memberType: Type;
  sortCompare: ClientHandlerDescriptor;
  sortDirection: ListSortDirection;
}

export type ICustomAttributeProvider = object;

export interface IFilterDescriptor {
  caseSensitiveFilter: boolean;
}

export type IntPtr = object;

/** @format int32 */
export type LayoutKind = 0 | 2 | 3;

/** @format int32 */
export type ListSortDirection = 0 | 1;

export interface MemberInfo {
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  isCollectible: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  module: Module;
  name: string;
  reflectedType: Type;
}

/** @format int32 */
export type MemberTypes = 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 191;

export interface MenuItemRequest {
  description?: string | null;
  /** @format int32 */
  id: number;
  name: string;
  url: string;
}

export interface MenuRoleBusinessUnitIdListParams {
  /** @format int32 */
  businessUnitId?: number;
  request?: DataSourceRequest;
}

/** @format int32 */
export type MethodAttributes =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 16
  | 32
  | 64
  | 128
  | 256
  | 512
  | 1024
  | 2048
  | 4096
  | 8192
  | 16384
  | 32768
  | 53248;

export interface MethodBase {
  attributes: MethodAttributes;
  callingConvention: CallingConventions;
  containsGenericParameters: boolean;
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  isAbstract: boolean;
  isAssembly: boolean;
  isCollectible: boolean;
  isConstructedGenericMethod: boolean;
  isConstructor: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isFinal: boolean;
  isGenericMethod: boolean;
  isGenericMethodDefinition: boolean;
  isHideBySig: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isVirtual: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  methodHandle: RuntimeMethodHandle;
  methodImplementationFlags: MethodImplAttributes;
  module: Module;
  name: string;
  reflectedType: Type;
}

/** @format int32 */
export type MethodImplAttributes = 0 | 1 | 2 | 3 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 4096 | 65535;

export interface MethodInfo {
  attributes: MethodAttributes;
  callingConvention: CallingConventions;
  containsGenericParameters: boolean;
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  isAbstract: boolean;
  isAssembly: boolean;
  isCollectible: boolean;
  isConstructedGenericMethod: boolean;
  isConstructor: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isFinal: boolean;
  isGenericMethod: boolean;
  isGenericMethodDefinition: boolean;
  isHideBySig: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isVirtual: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  methodHandle: RuntimeMethodHandle;
  methodImplementationFlags: MethodImplAttributes;
  module: Module;
  name: string;
  reflectedType: Type;
  returnParameter: ParameterInfo;
  returnType: Type;
  returnTypeCustomAttributes: ICustomAttributeProvider;
}

export interface Module {
  assembly: Assembly;
  customAttributes: CustomAttributeData[];
  fullyQualifiedName: string;
  /** @format int32 */
  mdStreamVersion: number;
  /** @format int32 */
  metadataToken: number;
  moduleHandle: ModuleHandle;
  /** @format uuid */
  moduleVersionId: string;
  name: string;
  scopeName: string;
}

export interface ModuleHandle {
  /** @format int32 */
  mdStreamVersion: number;
}

export interface ObjectObjectFunc {
  method: MethodInfo;
  target?: any;
}

export interface OrganisationListParams {
  request?: DataSourceRequest;
}

/** @format int32 */
export type ParameterAttributes = 0 | 1 | 2 | 4 | 8 | 16 | 4096 | 8192 | 16384 | 32768 | 61440;

export interface ParameterInfo {
  attributes: ParameterAttributes;
  customAttributes: CustomAttributeData[];
  defaultValue?: any;
  hasDefaultValue: boolean;
  isIn: boolean;
  isLcid: boolean;
  isOptional: boolean;
  isOut: boolean;
  isRetval: boolean;
  member: MemberInfo;
  /** @format int32 */
  metadataToken: number;
  name?: string | null;
  parameterType: Type;
  /** @format int32 */
  position: number;
  rawDefaultValue?: any;
}

/** @format int32 */
export type PropertyAttributes = 0 | 512 | 1024 | 4096 | 8192 | 16384 | 32768 | 62464;

export interface PropertyInfo {
  attributes: PropertyAttributes;
  canRead: boolean;
  canWrite: boolean;
  customAttributes: CustomAttributeData[];
  declaringType: Type;
  getMethod: MethodInfo;
  isCollectible: boolean;
  isSpecialName: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  module: Module;
  name: string;
  propertyType: Type;
  reflectedType: Type;
  setMethod: MethodInfo;
}

export interface RoleListParams {
  request?: DataSourceRequest;
}

export interface RuntimeFieldHandle {
  value: IntPtr;
}

export interface RuntimeMethodHandle {
  value: IntPtr;
}

export interface RuntimeTypeHandle {
  value: IntPtr;
}

/** @format int32 */
export type SecurityRuleSet = 0 | 1 | 2;

export interface SetPasswordRequest {
  /** @format email */
  email: string;
  password: string;
  token: string;
}

export interface SortDescriptor {
  member?: string | null;
  sortCompare: ClientHandlerDescriptor;
  sortDirection: ListSortDirection;
}

export interface StringStringFAnonymousType0 {
  message?: string | null;
  status?: string | null;
}

export interface StructLayoutAttribute {
  typeId: any;
  value: LayoutKind;
}

/** @format int32 */
export type TokenPurpose = 0 | 1 | 2;

export interface Type {
  assembly: Assembly;
  assemblyQualifiedName?: string | null;
  attributes: TypeAttributes;
  baseType: Type;
  containsGenericParameters: boolean;
  customAttributes: CustomAttributeData[];
  declaringMethod: MethodBase;
  declaringType: Type;
  fullName?: string | null;
  genericParameterAttributes: GenericParameterAttributes;
  /** @format int32 */
  genericParameterPosition: number;
  genericTypeArguments: Type[];
  /** @format uuid */
  guid: string;
  hasElementType: boolean;
  isAbstract: boolean;
  isAnsiClass: boolean;
  isArray: boolean;
  isAutoClass: boolean;
  isAutoLayout: boolean;
  isByRef: boolean;
  isByRefLike: boolean;
  isCOMObject: boolean;
  isClass: boolean;
  isCollectible: boolean;
  isConstructedGenericType: boolean;
  isContextful: boolean;
  isEnum: boolean;
  isExplicitLayout: boolean;
  isFunctionPointer: boolean;
  isGenericMethodParameter: boolean;
  isGenericParameter: boolean;
  isGenericType: boolean;
  isGenericTypeDefinition: boolean;
  isGenericTypeParameter: boolean;
  isImport: boolean;
  isInterface: boolean;
  isLayoutSequential: boolean;
  isMarshalByRef: boolean;
  isNested: boolean;
  isNestedAssembly: boolean;
  isNestedFamANDAssem: boolean;
  isNestedFamORAssem: boolean;
  isNestedFamily: boolean;
  isNestedPrivate: boolean;
  isNestedPublic: boolean;
  isNotPublic: boolean;
  isPointer: boolean;
  isPrimitive: boolean;
  isPublic: boolean;
  isSZArray: boolean;
  isSealed: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  /** @deprecated */
  isSerializable: boolean;
  isSignatureType: boolean;
  isSpecialName: boolean;
  isTypeDefinition: boolean;
  isUnicodeClass: boolean;
  isUnmanagedFunctionPointer: boolean;
  isValueType: boolean;
  isVariableBoundArray: boolean;
  isVisible: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  module: Module;
  name: string;
  namespace?: string | null;
  reflectedType: Type;
  structLayoutAttribute: StructLayoutAttribute;
  typeHandle: RuntimeTypeHandle;
  typeInitializer: ConstructorInfo;
  underlyingSystemType: Type;
}

/** @format int32 */
export type TypeAttributes =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 16
  | 24
  | 32
  | 128
  | 256
  | 1024
  | 2048
  | 4096
  | 8192
  | 16384
  | 65536
  | 131072
  | 196608
  | 262144
  | 264192
  | 1048576
  | 12582912;

export interface TypeInfo {
  assembly: Assembly;
  assemblyQualifiedName?: string | null;
  attributes: TypeAttributes;
  baseType: Type;
  containsGenericParameters: boolean;
  customAttributes: CustomAttributeData[];
  declaredConstructors: ConstructorInfo[];
  declaredEvents: EventInfo[];
  declaredFields: FieldInfo[];
  declaredMembers: MemberInfo[];
  declaredMethods: MethodInfo[];
  declaredNestedTypes: TypeInfo[];
  declaredProperties: PropertyInfo[];
  declaringMethod: MethodBase;
  declaringType: Type;
  fullName?: string | null;
  genericParameterAttributes: GenericParameterAttributes;
  /** @format int32 */
  genericParameterPosition: number;
  genericTypeArguments: Type[];
  genericTypeParameters: Type[];
  /** @format uuid */
  guid: string;
  hasElementType: boolean;
  implementedInterfaces: Type[];
  isAbstract: boolean;
  isAnsiClass: boolean;
  isArray: boolean;
  isAutoClass: boolean;
  isAutoLayout: boolean;
  isByRef: boolean;
  isByRefLike: boolean;
  isCOMObject: boolean;
  isClass: boolean;
  isCollectible: boolean;
  isConstructedGenericType: boolean;
  isContextful: boolean;
  isEnum: boolean;
  isExplicitLayout: boolean;
  isFunctionPointer: boolean;
  isGenericMethodParameter: boolean;
  isGenericParameter: boolean;
  isGenericType: boolean;
  isGenericTypeDefinition: boolean;
  isGenericTypeParameter: boolean;
  isImport: boolean;
  isInterface: boolean;
  isLayoutSequential: boolean;
  isMarshalByRef: boolean;
  isNested: boolean;
  isNestedAssembly: boolean;
  isNestedFamANDAssem: boolean;
  isNestedFamORAssem: boolean;
  isNestedFamily: boolean;
  isNestedPrivate: boolean;
  isNestedPublic: boolean;
  isNotPublic: boolean;
  isPointer: boolean;
  isPrimitive: boolean;
  isPublic: boolean;
  isSZArray: boolean;
  isSealed: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  /** @deprecated */
  isSerializable: boolean;
  isSignatureType: boolean;
  isSpecialName: boolean;
  isTypeDefinition: boolean;
  isUnicodeClass: boolean;
  isUnmanagedFunctionPointer: boolean;
  isValueType: boolean;
  isVariableBoundArray: boolean;
  isVisible: boolean;
  memberType: MemberTypes;
  /** @format int32 */
  metadataToken: number;
  module: Module;
  name: string;
  namespace?: string | null;
  reflectedType: Type;
  structLayoutAttribute: StructLayoutAttribute;
  typeHandle: RuntimeTypeHandle;
  typeInitializer: ConstructorInfo;
  underlyingSystemType: Type;
}

export interface UpdateBusinessUnitBankRequest {
  accountNumber?: string | null;
  /** @format int32 */
  currencyId: number;
  iban?: string | null;
  /** @format int32 */
  id: number;
  sortCode?: string | null;
  vatRegNumber?: string | null;
}

export interface UpdateBusinessUnitRequest {
  address?: string | null;
  availableLegTypes: number[];
  availableStageTypes: number[];
  /** @format int32 */
  currencyId?: number | null;
  description: string;
  email?: string | null;
  fax?: string | null;
  id: string;
  internalCode: string;
  logo?: string | null;
  name: string;
  /** @format uuid */
  organisationId: string;
  phone?: string | null;
  transferCustomerAccountCode?: string | null;
  zipCode?: string | null;
}

export interface UpdateBusinessUnitSettingsRequest {
  /** @format int32 */
  id: number;
  key: string;
  value?: string | null;
}

export interface UpdateOrganisationRequest {
  description: string;
  /** @format uuid */
  id: string;
  name: string;
}

export interface UpdateUserRequest {
  /** @format int32 */
  driverId?: number | null;
  email: string;
  firstName: string;
  /** @format uuid */
  id: string;
  lastName: string;
  /** @format uuid */
  organisationId?: string | null;
  roleId: string;
  status: UserStatus;
}

export interface UserByRoleIdDetailParams {
  request?: DataSourceRequest;
  roleId: string;
}

export interface UserByRoleNameDetailParams {
  request?: DataSourceRequest;
  roleName: string;
}

export interface UserByRoleNamesListParams {
  request?: DataSourceRequest;
  roleNames?: string[];
}

export type UserLinkToBusinessUnitCreatePayload = number[];

export interface UserListParams {
  RoleIds?: string[];
  UserIds?: string[];
  request?: DataSourceRequest;
}

export interface UserLoginRequest {
  password: string;
  username: string;
}

export interface UserLookupListParams {
  RoleIds?: string[];
  UserIds?: string[];
}

/** @format int32 */
export type UserStatus = 0 | 1 | 2;

export interface ValidationError {
  code?: string | null;
  message?: string | null;
  parameters?: Record<string, string | null>;
}

export interface ViewBaseResponse {
  id: string;
  name: string;
}

export interface ViewBusinessUnitBankResponse {
  accountNumber?: string | null;
  /** @format int32 */
  currencyId: number;
  iban?: string | null;
  /** @format int32 */
  id: number;
  sortCode?: string | null;
  vatRegNumber?: string | null;
}

export interface ViewBusinessUnitResponse {
  address?: string | null;
  availableLegTypes: number[];
  availableStageTypes: number[];
  bankDetails: ViewBusinessUnitBankResponse[];
  /** @format int32 */
  currencyId?: number | null;
  description: string;
  email?: string | null;
  fax?: string | null;
  id: string;
  internalCode: string;
  logo?: string | null;
  name: string;
  /** @format uuid */
  organisationId: string;
  phone?: string | null;
  transferCustomerAccountCode?: string | null;
  zipCode?: string | null;
}

export interface ViewBusinessUnitResponseDataSourceResultGeneric {
  aggregateResults: any[];
  data: ViewBusinessUnitResponse[];
  errors: any;
  /** @format int32 */
  total: number;
}

export interface ViewBusinessUnitSettingsResponse {
  /** @format int32 */
  id: number;
  key?: string | null;
  value?: string | null;
}

export interface ViewDriverResponse {
  emails: string[];
  /** @format int32 */
  id: number;
  name: string;
}

export interface ViewMenuRoleResponse {
  businessUnit: ViewBusinessUnitBankResponse;
  /** @format int32 */
  businessUnitId: number;
  canWrite: boolean;
  menuItem: MenuItemRequest;
  /** @format int32 */
  menuItemId: number;
  roleId: string;
}

export interface ViewMenuRoleResponseDataSourceResultGeneric {
  aggregateResults: any[];
  data: ViewMenuRoleResponse[];
  errors: any;
  /** @format int32 */
  total: number;
}

export interface ViewOrganisationResponse {
  description: string;
  /** @format uuid */
  id: string;
  name: string;
}

export interface ViewOrganisationResponseDataSourceResultGeneric {
  aggregateResults: any[];
  data: ViewOrganisationResponse[];
  errors: any;
  /** @format int32 */
  total: number;
}

export interface ViewRoleResponse {
  id: string;
  name: string;
}

export interface ViewRoleResponseDataSourceResultGeneric {
  aggregateResults: any[];
  data: ViewRoleResponse[];
  errors: any;
  /** @format int32 */
  total: number;
}

export interface ViewUserDriverResponse {
  driver: ViewDriverResponse;
  notLinkedDrivers: ViewDriverResponse[];
  user: ViewUserResponse;
}

export interface ViewUserResponse {
  businessUnitIds: number[];
  /** @format int32 */
  driverId?: number | null;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  roles: ViewRoleResponse[];
  status: UserStatus;
}

export interface ViewUserResponseDataSourceResultGeneric {
  aggregateResults: any[];
  data: ViewUserResponse[];
  errors: any;
  /** @format int32 */
  total: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Celerum TLS Authentication.API
 * @version v1
 */
export class AuthenticationApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  authentication = {
    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationActivateAccountCreate
     * @summary Activates an account
     * @request POST:/authentication-gateway/Authentication/ActivateAccount
     * @secure
     * @response `200` `void` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationActivateAccountCreate: (data: SetPasswordRequest, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication/ActivateAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationCheckTokenValidCreate
     * @summary Check if token is valid
     * @request POST:/authentication-gateway/Authentication/CheckTokenValid
     * @secure
     * @response `200` `void` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationCheckTokenValidCreate: (data: CheckTokenRequest, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication/CheckTokenValid`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationCreate
     * @summary Logs in a user
     * @request POST:/authentication-gateway/Authentication
     * @secure
     * @response `200` `AuthenticationResponse` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationCreate: (data: UserLoginRequest, params: RequestParams = {}) =>
      this.request<AuthenticationResponse, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationForgotPasswordCreate
     * @summary Sends a forgot password email
     * @request POST:/authentication-gateway/Authentication/ForgotPassword
     * @secure
     * @response `200` `void` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationForgotPasswordCreate: (data: ForgotPasswordRequest, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication/ForgotPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationLogoutCreate
     * @summary Logs out a user
     * @request POST:/authentication-gateway/Authentication/Logout
     * @secure
     * @response `200` `void` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication/Logout`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationResendActivationEmailCreate
     * @summary Resends the activation email
     * @request POST:/authentication-gateway/Authentication/ResendActivationEmail
     * @secure
     * @response `200` `void` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationResendActivationEmailCreate: (data: ForgotPasswordRequest, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication/ResendActivationEmail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationResetPasswordCreate
     * @summary Resets a user's password
     * @request POST:/authentication-gateway/Authentication/ResetPassword
     * @secure
     * @response `200` `void` OK
     * @response `404` `GlobalErrorModel` Not Found
     */
    authenticationResetPasswordCreate: (data: SetPasswordRequest, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel>({
        path: `/authentication-gateway/Authentication/ResetPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  businessUnit = {
    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitCreate
     * @summary Creates a business unit
     * @request POST:/authentication-gateway/BusinessUnit
     * @secure
     * @response `200` `ViewBusinessUnitResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitCreate: (data: CreateBusinessUnitRequest, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitDelete
     * @summary Deletes a business unit
     * @request DELETE:/authentication-gateway/BusinessUnit/{id}
     * @secure
     * @response `200` `void` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnit/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitDetail
     * @summary Gets an business unit by its ID
     * @request GET:/authentication-gateway/BusinessUnit/{id}
     * @secure
     * @response `200` `ViewBusinessUnitResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitDetail: (id: string, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnit/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitList
     * @summary Get all business units
     * @request GET:/authentication-gateway/BusinessUnit
     * @deprecated
     * @secure
     * @response `200` `ViewBusinessUnitResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    businessUnitList: (query: BusinessUnitListParams, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/BusinessUnit`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitLookupList
     * @summary Get all business units (lookup)
     * @request GET:/authentication-gateway/BusinessUnit/lookup
     * @secure
     * @response `200` `(ViewBaseResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    businessUnitLookupList: (params: RequestParams = {}) =>
      this.request<ViewBaseResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/BusinessUnit/lookup`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitOrganisationList
     * @summary Get all business units for an organisation
     * @request GET:/authentication-gateway/BusinessUnit/organisation
     * @deprecated
     * @secure
     * @response `200` `ViewBusinessUnitResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    businessUnitOrganisationList: (query: BusinessUnitOrganisationListParams, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/BusinessUnit/organisation`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitOrganisationListList
     * @summary Get all business units for an organisation
     * @request GET:/authentication-gateway/BusinessUnit/organisation/list
     * @secure
     * @response `200` `(ViewBusinessUnitResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    businessUnitOrganisationListList: (params: RequestParams = {}) =>
      this.request<ViewBusinessUnitResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/BusinessUnit/organisation/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnit
     * @name BusinessUnitUpdate
     * @summary Updates a business unit
     * @request PUT:/authentication-gateway/BusinessUnit
     * @secure
     * @response `200` `ViewBusinessUnitResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitUpdate: (data: UpdateBusinessUnitRequest, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnit`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  businessUnitBank = {
    /**
     * No description
     *
     * @tags BusinessUnitBank
     * @name BusinessUnitBankCreate
     * @summary Creates business unit's bank details
     * @request POST:/authentication-gateway/BusinessUnitBank
     * @secure
     * @response `200` `ViewBusinessUnitBankResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitBankCreate: (data: CreateBusinessUnitBankRequest, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitBankResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitBank`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitBank
     * @name BusinessUnitBankDelete
     * @summary Deletes business unit's bank details
     * @request DELETE:/authentication-gateway/BusinessUnitBank/{id}
     * @secure
     * @response `200` `void` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitBankDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitBank/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitBank
     * @name BusinessUnitBankDetail
     * @summary Gets an business unit's bank details by its ID
     * @request GET:/authentication-gateway/BusinessUnitBank/{id}
     * @secure
     * @response `200` `ViewBusinessUnitBankResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitBankDetail: (id: number, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitBankResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitBank/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitBank
     * @name BusinessUnitBankList
     * @summary Gets business unit's bank details
     * @request GET:/authentication-gateway/BusinessUnitBank
     * @secure
     * @response `200` `(ViewBusinessUnitBankResponse)[]` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    businessUnitBankList: (params: RequestParams = {}) =>
      this.request<ViewBusinessUnitBankResponse[], StringStringFAnonymousType0>({
        path: `/authentication-gateway/BusinessUnitBank`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitBank
     * @name BusinessUnitBankUpdate
     * @summary Updates business unit's bank details
     * @request PUT:/authentication-gateway/BusinessUnitBank
     * @secure
     * @response `200` `ViewBusinessUnitBankResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitBankUpdate: (data: UpdateBusinessUnitBankRequest, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitBankResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitBank`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  businessUnitSettings = {
    /**
     * No description
     *
     * @tags BusinessUnitSettings
     * @name BusinessUnitSettingsCreate
     * @summary Creates business unit's setting
     * @request POST:/authentication-gateway/BusinessUnitSettings
     * @secure
     * @response `200` `ViewBusinessUnitSettingsResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitSettingsCreate: (data: CreateBusinessUnitSettingsRequest, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitSettingsResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitSettings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitSettings
     * @name BusinessUnitSettingsDelete
     * @summary Deletes business unit's setting
     * @request DELETE:/authentication-gateway/BusinessUnitSettings/{id}
     * @secure
     * @response `200` `void` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitSettingsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitSettings/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitSettings
     * @name BusinessUnitSettingsDetail
     * @summary Gets an business unit's bank setting by its ID
     * @request GET:/authentication-gateway/BusinessUnitSettings/{id}
     * @secure
     * @response `200` `ViewBusinessUnitSettingsResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitSettingsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitSettingsResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitSettings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitSettings
     * @name BusinessUnitSettingsList
     * @summary Gets business unit's settings
     * @request GET:/authentication-gateway/BusinessUnitSettings
     * @secure
     * @response `200` `(ViewBusinessUnitSettingsResponse)[]` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    businessUnitSettingsList: (params: RequestParams = {}) =>
      this.request<ViewBusinessUnitSettingsResponse[], StringStringFAnonymousType0>({
        path: `/authentication-gateway/BusinessUnitSettings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessUnitSettings
     * @name BusinessUnitSettingsUpdate
     * @summary Updates business unit's setting
     * @request PUT:/authentication-gateway/BusinessUnitSettings
     * @secure
     * @response `200` `ViewBusinessUnitSettingsResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    businessUnitSettingsUpdate: (data: UpdateBusinessUnitSettingsRequest, params: RequestParams = {}) =>
      this.request<ViewBusinessUnitSettingsResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/BusinessUnitSettings`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  menuRole = {
    /**
     * No description
     *
     * @tags MenuRole
     * @name MenuRoleBusinessUnitIdList
     * @summary Get menu roles for the business unit id
     * @request GET:/authentication-gateway/MenuRole/businessUnitId
     * @deprecated
     * @secure
     * @response `200` `ViewMenuRoleResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    menuRoleBusinessUnitIdList: (query: MenuRoleBusinessUnitIdListParams, params: RequestParams = {}) =>
      this.request<ViewMenuRoleResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/MenuRole/businessUnitId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MenuRole
     * @name MenuRoleCreate
     * @summary Add new menu role permission
     * @request POST:/authentication-gateway/MenuRole
     * @secure
     * @response `200` `ViewMenuRoleResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    menuRoleCreate: (data: AddMenuRoleRequest, params: RequestParams = {}) =>
      this.request<ViewMenuRoleResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/MenuRole`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  organisation = {
    /**
     * No description
     *
     * @tags Organisation
     * @name OrganisationCreate
     * @summary Creates a organisation
     * @request POST:/authentication-gateway/Organisation
     * @secure
     * @response `200` `ViewOrganisationResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    organisationCreate: (data: CreateOrganisationRequest, params: RequestParams = {}) =>
      this.request<ViewOrganisationResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/Organisation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisation
     * @name OrganisationDelete
     * @summary Deletes a organisation
     * @request DELETE:/authentication-gateway/Organisation/{id}
     * @secure
     * @response `200` `void` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    organisationDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/Organisation/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisation
     * @name OrganisationDetail
     * @summary Gets an organisation by its ID
     * @request GET:/authentication-gateway/Organisation/{id}
     * @secure
     * @response `200` `ViewOrganisationResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    organisationDetail: (id: string, params: RequestParams = {}) =>
      this.request<ViewOrganisationResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/Organisation/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisation
     * @name OrganisationList
     * @summary Get all organisations
     * @request GET:/authentication-gateway/Organisation
     * @deprecated
     * @secure
     * @response `200` `ViewOrganisationResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    organisationList: (query: OrganisationListParams, params: RequestParams = {}) =>
      this.request<ViewOrganisationResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/Organisation`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisation
     * @name OrganisationLookupList
     * @summary Get all organisations (lookup)
     * @request GET:/authentication-gateway/Organisation/lookup
     * @secure
     * @response `200` `(ViewBaseResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    organisationLookupList: (params: RequestParams = {}) =>
      this.request<ViewBaseResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/Organisation/lookup`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisation
     * @name OrganisationUpdate
     * @summary Updates a organisation
     * @request PUT:/authentication-gateway/Organisation
     * @secure
     * @response `200` `ViewOrganisationResponse` OK
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    organisationUpdate: (data: UpdateOrganisationRequest, params: RequestParams = {}) =>
      this.request<ViewOrganisationResponse, StringStringFAnonymousType0 | GlobalErrorModel>({
        path: `/authentication-gateway/Organisation`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  role = {
    /**
     * No description
     *
     * @tags Role
     * @name RoleList
     * @summary Gets all roles
     * @request GET:/authentication-gateway/Role
     * @deprecated
     * @secure
     * @response `200` `ViewRoleResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    roleList: (query: RoleListParams, params: RequestParams = {}) =>
      this.request<ViewRoleResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/Role`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleLookupList
     * @summary Gets all roles (lookup)
     * @request GET:/authentication-gateway/Role/lookup
     * @secure
     * @response `200` `(ViewBaseResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    roleLookupList: (params: RequestParams = {}) =>
      this.request<ViewBaseResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/Role/lookup`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name UserByRoleIdDetail
     * @summary Gets users by role id
     * @request GET:/authentication-gateway/User/ByRoleId/{roleId}
     * @deprecated
     * @secure
     * @response `200` `ViewUserResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userByRoleIdDetail: ({ roleId, ...query }: UserByRoleIdDetailParams, params: RequestParams = {}) =>
      this.request<ViewUserResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/ByRoleId/${roleId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserByRoleNameDetail
     * @summary Gets users by role name
     * @request GET:/authentication-gateway/User/ByRoleName/{roleName}
     * @deprecated
     * @secure
     * @response `200` `ViewUserResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userByRoleNameDetail: ({ roleName, ...query }: UserByRoleNameDetailParams, params: RequestParams = {}) =>
      this.request<ViewUserResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/ByRoleName/${roleName}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserByRoleNamesList
     * @summary Gets users by role names
     * @request GET:/authentication-gateway/User/ByRoleNames
     * @deprecated
     * @secure
     * @response `200` `ViewUserResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userByRoleNamesList: (query: UserByRoleNamesListParams, params: RequestParams = {}) =>
      this.request<ViewUserResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/ByRoleNames`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserCreate
     * @summary Create user
     * @request POST:/authentication-gateway/User
     * @secure
     * @response `200` `ViewUserResponse` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    userCreate: (data: CreateUserRequest, params: RequestParams = {}) =>
      this.request<ViewUserResponse, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDelete
     * @summary Delete user
     * @request DELETE:/authentication-gateway/User/{id}
     * @secure
     * @response `200` `void` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    userDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDetail
     * @summary Gets user by its ID
     * @request GET:/authentication-gateway/User/{id}
     * @secure
     * @response `200` `ViewUserResponse` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userDetail: (id: string, params: RequestParams = {}) =>
      this.request<ViewUserResponse, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDriverInfoDetail
     * @request GET:/authentication-gateway/User/{id}/driver-info
     * @secure
     * @response `200` `ViewUserDriverResponse` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userDriverInfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<ViewUserDriverResponse, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/${id}/driver-info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserLinkToBusinessUnitCreate
     * @summary Link user to business unit
     * @request POST:/authentication-gateway/User/{id}/link-to-business-unit
     * @secure
     * @response `200` `void` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    userLinkToBusinessUnitCreate: (id: string, data: UserLinkToBusinessUnitCreatePayload, params: RequestParams = {}) =>
      this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/${id}/link-to-business-unit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserList
     * @summary Gets all users
     * @request GET:/authentication-gateway/User
     * @deprecated
     * @secure
     * @response `200` `ViewUserResponseDataSourceResultGeneric` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userList: (query: UserListParams, params: RequestParams = {}) =>
      this.request<ViewUserResponseDataSourceResultGeneric, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserListList
     * @summary Gets all users
     * @request GET:/authentication-gateway/User/list
     * @secure
     * @response `200` `(ViewUserResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userListList: (params: RequestParams = {}) =>
      this.request<ViewUserResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserLookupList
     * @summary Gets all users (lookup)
     * @request GET:/authentication-gateway/User/lookup
     * @secure
     * @response `200` `(ViewBaseResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    userLookupList: (query: UserLookupListParams, params: RequestParams = {}) =>
      this.request<ViewBaseResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/lookup`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserSearchCreate
     * @summary Search users by filter
     * @request POST:/authentication-gateway/User/search
     * @secure
     * @response `200` `(ViewUserResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    userSearchCreate: (data: GenericQueryFilter, params: RequestParams = {}) =>
      this.request<ViewUserResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdate
     * @summary Update user
     * @request PUT:/authentication-gateway/User
     * @secure
     * @response `200` `ViewUserResponse` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    userUpdate: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<ViewUserResponse, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/authentication-gateway/User`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  version = {
    /**
     * No description
     *
     * @tags Version
     * @name VersionList
     * @summary Get version number of the API.
     * @request GET:/authentication-gateway/version
     * @secure
     * @response `200` `GetVersionResponse` OK
     * @response `500` `string` Internal Server Error
     */
    versionList: (params: RequestParams = {}) =>
      this.request<GetVersionResponse, string>({
        path: `/authentication-gateway/version`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
