/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BankModel {
  accountNumber?: string | null;
  currencyCode?: string | null;
  iban?: string | null;
  sortCode?: string | null;
  vatRegNumber?: string | null;
}

export interface BusinessUnitModel {
  address?: string | null;
  email?: string | null;
  internalCode: string;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface CargoLabelModel {
  businessUnit: BusinessUnitModel;
  collectionLocation: LocationModel;
  deliveryLocation: LocationModel;
  job: JobModel;
  organisation: OrganisationModel;
  /** @format int32 */
  quantity: number;
  userName?: string | null;
}

export interface CmrModel {
  businessUnit: BusinessUnitModel;
  customer: CustomerModel;
  customsLeg: LegModel;
  goods: GoodsReportModel;
  job: JobModel;
  organisation: OrganisationModel;
  transportationLeg: LegModel;
  userName?: string | null;
}

export interface CollectionNoteReportModel {
  businessUnit: BusinessUnitModel;
  collectionLeg: LegModel;
  customer: CustomerModel;
  deliveryLeg: LegModel;
  goods: GoodsReportModel;
  job: JobModel;
  load: LoadModel;
  organisation: OrganisationModel;
  userName?: string | null;
}

export interface CustomerModel {
  accountCode?: string | null;
  address?: string | null;
  currencyCode?: string | null;
  emails: string[];
  isEuCustomer: boolean;
  name?: string | null;
  phones: string[];
  vatNumber?: string | null;
}

export interface DeliveryTicketReportModel {
  businessUnit: BusinessUnitModel;
  collectionLeg: LegModel;
  customer: CustomerModel;
  deliveryLeg: LegModel;
  goods: GoodsReportModel;
  job: JobModel;
  load: LoadModel;
  organisation: OrganisationModel;
  userName?: string | null;
}

export interface FileFrontReportModel {
  businessUnit: BusinessUnitModel;
  customer: CustomerModel;
  endLocation: LocationModel;
  goods: GoodsReportModel[];
  job: JobModel;
  organisation: OrganisationModel;
  startLocation: LocationModel;
  userName?: string | null;
}

export interface GetVersionResponse {
  version?: string | null;
}

export interface GlobalErrorModel {
  /** @format int32 */
  errorCode?: number | null;
  errors: ValidationError[];
  innerException?: string | null;
  message: string;
  stackTrace?: string | null;
}

export interface GoodsReportModel {
  associatedLoads: LoadModel[];
  clearCustoms: LegModel[];
  /** @format date-time */
  collectionDate: string;
  collectionLocation: LocationModel;
  /** @format date-time */
  deliveryDate: string;
  deliveryLocation: LocationModel;
  dimensions?: string | null;
  /** @format int32 */
  id: number;
  job: JobModel;
  name?: string | null;
  /** @format int32 */
  quantity: number;
  specialRequirements: string[];
  uniqueId?: string | null;
  /** @format double */
  weight: number;
}

export interface InvoiceDetailReportModel {
  endLocation: LocationModel;
  goods: GoodsReportModel[];
  /** @format double */
  grossSum: number;
  /** @format int32 */
  id: number;
  jobTypeName?: string | null;
  jobTypeNominalCode?: string | null;
  /** @format double */
  price: number;
  purchaseOrderNumber?: string | null;
  /** @format date-time */
  startDate: string;
  startLocation: LocationModel;
  uniqueId?: string | null;
  /** @format double */
  vatRate: number;
  /** @format double */
  vatSum: number;
}

export interface InvoiceReportModel {
  bankDetails: BankModel;
  businessUnit: BusinessUnitModel;
  customer: CustomerModel;
  /** @format date-time */
  date?: string | null;
  details: InvoiceDetailReportModel[];
  invoiceTemplate?: string | null;
  number?: string | null;
  organisation: OrganisationModel;
  userName?: string | null;
}

export interface JobModel {
  assignedOperator?: string | null;
  /** @format date-time */
  endDate: string;
  purchaseOrderNumber?: string | null;
  /** @format date-time */
  startDate: string;
  typeName?: string | null;
  uniqueId?: string | null;
}

export interface LegModel {
  t1?: string | null;
  /** @format date-time */
  clearanceDate?: string | null;
  clearanceLocation?: string | null;
  /** @format date-time */
  collectionDate?: string | null;
  collectionLocation: LocationModel;
  /** @format double */
  cost: number;
  currencyCode?: string | null;
  /** @format date-time */
  deliveryDate?: string | null;
  deliveryLocation: LocationModel;
  driver?: string | null;
  ferryReferenceNumber?: string | null;
  ferryRoute?: string | null;
  /** @format date-time */
  ferrySailingDate?: string | null;
  goods: GoodsReportModel[];
  /** @format int32 */
  id: number;
  mrn?: string | null;
  notes?: string | null;
  subcontractor?: string | null;
  trailer?: string | null;
  truck?: string | null;
  ucr?: string | null;
  uniqueId?: string | null;
}

export interface LoadModel {
  driver?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id: number;
  /** @format date-time */
  startDate?: string | null;
  subcontractor?: string | null;
  trailer?: string | null;
  truck?: string | null;
  uniqueId?: string | null;
}

export interface LocationModel {
  address?: string | null;
  name?: string | null;
}

export interface ManifestReportModel {
  businessUnit: BusinessUnitModel;
  endLocation: LocationModel;
  ferryLegs: LegModel[];
  goods: GoodsReportModel[];
  load: LoadModel;
  organisation: OrganisationModel;
  startLocation: LocationModel;
  userName?: string | null;
}

export interface OrganisationModel {
  accountNumber?: string | null;
  iban?: string | null;
  logo?: string | null;
  name: string;
  sortCode?: string | null;
}

export interface StringStringFAnonymousType0 {
  message?: string | null;
  status?: string | null;
}

export interface SubcontractorOrderReportModel {
  businessUnit: BusinessUnitModel;
  customer: CustomerModel;
  job: JobModel;
  legs: LegModel[];
  organisation: OrganisationModel;
  userName?: string | null;
}

/** @format int32 */
export type TemplateNameType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export interface ValidationError {
  code?: string | null;
  message?: string | null;
  parameters?: Record<string, string | null>;
}

export interface ViewTemplateResponse {
  internalCode?: string | null;
  isDefault: boolean;
  name: string;
  path: string;
  type: TemplateNameType;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Celerum TLS Report.API
 * @version v1
 */
export class ReportApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  report = {
    /**
     * No description
     *
     * @tags Report
     * @name ReportV1GoodsCargoLabelCreate
     * @summary Returns cargo label PDF Report
     * @request POST:/report-gateway/report/v1/goods/cargoLabel
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1GoodsCargoLabelCreate: (data: CargoLabelModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/goods/cargoLabel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1GoodsCmrCreate
     * @summary Returns CMR PDF Report
     * @request POST:/report-gateway/report/v1/goods/cmr
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1GoodsCmrCreate: (data: CmrModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/goods/cmr`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1JobFileFrontCreate
     * @summary Returns job file front PDF Report
     * @request POST:/report-gateway/report/v1/job/fileFront
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1JobFileFrontCreate: (data: FileFrontReportModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/job/fileFront`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1JobInvoiceCreate
     * @summary Returns job invoice PDF Report
     * @request POST:/report-gateway/report/v1/job/invoice
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1JobInvoiceCreate: (data: InvoiceReportModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/job/invoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1LegCollectionNoteCreate
     * @summary Returns collection note PDF Report
     * @request POST:/report-gateway/report/v1/leg/collectionNote
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1LegCollectionNoteCreate: (data: CollectionNoteReportModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/leg/collectionNote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1LegDeliveryTicketCreate
     * @summary Returns delivery ticket PDF Report
     * @request POST:/report-gateway/report/v1/leg/deliveryTicket
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1LegDeliveryTicketCreate: (data: DeliveryTicketReportModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/leg/deliveryTicket`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1LegSubcontractorOrderCreate
     * @summary Returns subcontractor order PDF Report
     * @request POST:/report-gateway/report/v1/leg/subcontractorOrder
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1LegSubcontractorOrderCreate: (data: SubcontractorOrderReportModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/leg/subcontractorOrder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportV1LoadManifestCreate
     * @summary Returns load manifest PDF Report
     * @request POST:/report-gateway/report/v1/load/manifest
     * @secure
     * @response `200` `File` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     * @response `404` `GlobalErrorModel` Not Found
     */
    reportV1LoadManifestCreate: (data: ManifestReportModel, params: RequestParams = {}) =>
      this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/report/v1/load/manifest`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  template = {
    /**
     * No description
     *
     * @tags Template
     * @name TemplateV1Detail
     * @summary Get all templates by type
     * @request GET:/report-gateway/template/v1/{type}
     * @secure
     * @response `200` `(ViewTemplateResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    templateV1Detail: (type: TemplateNameType, params: RequestParams = {}) =>
      this.request<ViewTemplateResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/template/v1/${type}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateV1List
     * @summary Get all templates
     * @request GET:/report-gateway/template/v1
     * @secure
     * @response `200` `(ViewTemplateResponse)[]` OK
     * @response `400` `GlobalErrorModel` Bad Request
     * @response `401` `StringStringFAnonymousType0` Unauthorized
     */
    templateV1List: (params: RequestParams = {}) =>
      this.request<ViewTemplateResponse[], GlobalErrorModel | StringStringFAnonymousType0>({
        path: `/report-gateway/template/v1`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  version = {
    /**
     * No description
     *
     * @tags Version
     * @name VersionList
     * @summary Get version number of the API.
     * @request GET:/report-gateway/version
     * @secure
     * @response `200` `GetVersionResponse` OK
     * @response `500` `string` Internal Server Error
     */
    versionList: (params: RequestParams = {}) =>
      this.request<GetVersionResponse, string>({
        path: `/report-gateway/version`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
